import * as React from "react"
import { Helmet } from "react-helmet"
import BodyClassName from "react-body-classname"
import { Bar } from 'react-chartjs-2'

const B2WChartsPage: React.FC = () => {

  return (
    <>
      <BodyClassName className="bg-yellow-200">
        <main className="flex h-screen">
          <Helmet>
            <title>B2W Ads Chart | Publitools</title>
          </Helmet>

          <div>
              <Bar 

              />
          </div>

        </main>
      </BodyClassName>
    </>
  )
}

export default B2WChartsPage
